import {reactive} from "vue";
import userModel from "@/api/user";

//会员列表数据
export function useMemberList(){
    let mlState = reactive({
        search:{
            key:'',
            searchTime:[],
            sort:'1',
            user_id:"",
        },
        list:[],
        page:1,
        count:0,
        limit:10,
    })

    function getMemberList(page,limit){
        userModel.getAccount(page,limit,mlState.search,res=> {
            mlState.list = res.list
            mlState.page = res.page
            mlState.count = res.count
            mlState.limit = limit
        })
    }

    return { mlState ,getMemberList }
}

/**
 * 检测用户来源
 * @param type  来源类型
 * @returns {string}
 */
export function checkMemberOrigin(type){
    if( type === 1 ) return '微信小程序'
    if( type === 2 ) return '普通注册用户'
    if( type === 3 ) return '支付宝注册'
    if( type === 4 ) return 'QQ用户'
    if( type === 5 ) return '微信用户'
    if( type === 0 ) return '未知'
}


//手动设置会员等级
export function useSetUserLevel(fn){
    let sulState = reactive({
        level:[],
        show:false,
        level_id:null
    })

    function showSetLevel(){
        if( sulState.level.length === 0 ){
            userModel.getAccountLevel(1,999,res=>sulState.level = res.list)
        }
        sulState.show = true
    }
    function saveAccountLevelInfo(data){
        let param = {
            user_id:data.user_id,
            mobile:data.mobile,
            true_name:data.true_name,
            remark:data.remark,
            level_id:sulState.level_id
        }
        userModel.updateAccount(param,()=> {
            sulState.show = false
            fn()
        })
    }

    return { sulState ,showSetLevel,saveAccountLevelInfo}
}
